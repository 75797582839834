<template>
  <div>
    <div class="workplatform-title">费用汇总</div>

    <i-table stripe  :data="products" :columns="productColumns" :summary-method="handleSummary"
          show-summary></i-table>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'

export default {
  props: {
    productsPrice: Array
  },
  data () {
    return {
      productColumns: [
        { title: '资源名称', key: 'productName' },
        {
          title: '购买数量',
          align: 'center',
          render: (h, data) => {
            return h('span', data.row.quantity + ' ' + data.row.productUnit)
          }
        },
        {
          title: '媒体刊例总价',
          key: 'amount',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, this.formatMoney(data.row.amount))
          }
        },
        {
          title: '媒体发布费',
          key: 'realAmount',
          render: (h, data) => {
            return h('div', [
              h('span', {
                attrs: {
                  class: 'money'
                }
              }, this.formatMoney(data.row.realAmount)),
              h('span', {
                attrs: {
                  class: 'discount'
                }
              }, ' (' + this.computeDiscount(data.row) + '折)')

            ]

            )
          }
        },
        {
          title: '上下刊费',
          key: 'installPrice',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, this.formatMoney(data.row.installPrice))
          }
        },
        {
          title: '制作费',
          key: 'producePrice',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, this.formatMoney(data.row.producePrice))
          }
        },
        {
          title: '小计',
          key: 'total',
          render: (h, data) => {
            return h('span', {
              attrs: {
                class: 'money'
              }
            }, this.formatMoney(data.row.total))
          }
        }
      ]
    }
  },
  methods: {
    handleSummary ({ columns, data }) {
      const needSummarykey = [
        'amount', 'realAmount',
        'installPrice',
        'producePrice',
        'valueAddedServices',
        'total'
      ]
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        if (needSummarykey.includes(key)) {
          const values = data.map((item) => Number(item[key]))
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    formatMoney (number) {
      return toMoney(number)
    },
    computeDiscount (params) {
      let discount = 0
      if (params.amount !== 0) {
        discount = ((params.realAmount / params.amount) * 10).toFixed(2)
        // const discountArr = discount.split('.')
        // const integer = discountArr[0]
        // const decimal = discountArr[1] ? discountArr[1] : '00'
        // discount = integer + '.' + decimal.substr(0, 2)
      }

      return discount
    }
  },
  computed: {
    products: function () {
      const items = new Map()
      this.productsPrice.forEach(order => {
        order.settingProductfees.forEach(product => {
          const productPrice = items.get(product.goodsId) || {}
          let quantity = productPrice.quantity || 0
          let amount = productPrice.amount || 0 // 刊例价
          let realAmount = productPrice.realAmount || 0 // 最终执行价
          let producePrice = productPrice.producePrice || 0 // 原有制作费
          let installPrice = productPrice.installPrice || 0 // 原有上刊费

          // 获取当前产品内的费用
          quantity += product.quantity || 0
          amount += product.tusePrice || 0
          realAmount += product.usePrice || 0
          producePrice += product.producePrice || 0
          installPrice += product.installPrice || 0

          // 合计总价， 汇总添加当前产品的费用
          const productSummary = {
            productName: product.goodsName,
            quantity: quantity,
            realAmount: realAmount,
            amount: amount,
            producePrice: producePrice,
            installPrice: installPrice,
            total: realAmount + producePrice + installPrice,
            productUnit: product.productUnit
          }
          items.set(product.goodsId, productSummary)
        })
      })
      const summary = [...items.values()]

      return summary
    }

  }

}
</script>
