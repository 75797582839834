<template>
    <div>
        <agreementDetail :baseId="baseId"/>
    </div>
</template>

<script>
import agreementDetail from '@/components/agreement/DetailComponent'

export default {
  props: {
    baseId: {
      required: true
    }
  },
  components: {
    agreementDetail
  }
}
</script>
