import request from '@/utils/requestV2'
const qs = require('qs')

// 创建确认单
export function saveConfirmation (data) {
  return request({
    url: '/ooh-order/v1/confirmation/saveconfirmation',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取确认单列表
export function confirmationPage (data) {
  return request({
    url: '/ooh-order/v1/confirmation/confirmationpage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 删除确认单订单
export function delConfirmation (data) {
  return request({
    url: '/ooh-order/v1/confirmation/delconfirmation',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 确认单详情
export function getConfirmation (data) {
  return request({
    url: '/ooh-order/v1/confirmation/getconfirmation',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 订单集合获取确认单集合
export function getConfirmationIds (data) {
  return request({
    url: '/ooh-order/v1/confirmation/getconfirmationIds',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 作废确认单
export function cancelConfirmation (data) {
  return request({
    url: '/ooh-order/v1/confirmation/cancelconfirmation',
    method: 'POST',
    data: qs.stringify(data)
  })
}
