
<template>
    <div>
        <Row>
          <i-col span="24" class="text-right">
            <i-button type="primary" class="m-r-5" size="small" @click="showHistory=true">审批记录</i-button>
            <i-button type="primary" size="small" class="m-r-5"  @click="handleDownload">下载确认单</i-button>
            <i-button type="error" v-if="nullifiable" size="small" @click="handleInvalid">作废确认单</i-button>
          </i-col>
        </Row>
        <div class="text-center workplatform-title">
            媒体广告发布确认单
            <Tag :color="agreementStatus.color" > {{agreementStatus.name}}</Tag>
        </div>
        <Row>
            <i-col class="p-b-5">
               <span class="title"> 确认单名称 </span>
                <span>{{agreementBean.name}}</span>
            </i-col>
            <i-col class="p-b-5">
               <span class="title"> 所属客户 </span>
                <span>{{agreementBean.advertiserName}}</span>
            </i-col>
            <i-col class="p-b-5"> <span class="title">备注 </span>
                <span>{{agreementBean.desc}}</span>
            </i-col>
        </Row>

        <!-- 费用汇总 -->
        <price-summary :productsPrice="priceOrder"></price-summary>

        <Modal v-model="showHistory" :width="800" footer-hide>
            <history :relateId="agreementId" :type="approvalType" v-if="showHistory"></history>
        </Modal>
    </div>
</template>

<script>
import { downloadFileRequest } from '@/utils/download'
import { getConfirmation, cancelConfirmation } from '@/api/order/confirmation'

import priceSummary from '@/components/agreement/common/priceSummary'
import History from '@/components/approval/History'

export default {
  props: {
    baseId: {
      required: true
    }
  },
  components: {
    History, priceSummary
  },
  data () {
    return {
      agreementId: this.baseId,
      approvalType: 12,
      showHistory: false,
      agreementBean: {},
      agreementStatus: {},
      priceOrder: []
    }
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      const query = { confirmationId: this.agreementId }
      getConfirmation(query).then(res => {
        this.agreementBean = res
        const priceMap = new Map()
        res.orderIdList.forEach(orderId => {
          priceMap.set(orderId, {
            orderId: orderId,
            settingProductfees: res.confirmationItemVOList.filter(x => x.orderId === orderId)
          })
        })
        this.priceOrder = [...priceMap.values()]

        this.formatAgreementStatus(res.status)
      })
    },
    formatAgreementStatus (statusNum) {
      let status = {}

      if (statusNum === -1) {
        status = { color: '#3B3B3B', name: '已废弃' }
      } else if (statusNum === 0) { // 草稿
        status = { color: 'default', name: '草稿' }
      } else if (statusNum === 3) { // 创建合同中
        status = { color: '#a59426', name: '创建合同中' }
      } else if (statusNum === 4) { // 待审批
        status = { color: '#a59426', name: '待审批' }
      } else if (statusNum === 5) { // 已通过
        status = { color: '#44bd32', name: '已通过' }
      } else if (statusNum === 6) { // 已拒绝
        status = { color: '#ef4f4f', name: '已拒绝' }
      } else if (statusNum === 7) { // 已归档
        status = { color: '#141414', name: '已完成' }
      }

      this.agreementStatus = status
    },
    handleDownload () {
      const filename = '媒体广告发布确认单(' + this.agreementBean.name + ')' + '.xlsx'

      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-order/v1/order/getconfirmationexcel', { confirmationId: this.agreementId }, filename)
    },
    handleInvalid () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要作废该确认单嘛？',
        onOk: () => {
          cancelConfirmation({ confirmationId: this.agreementId }).then(
            res => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '确认单作废成功', title: '操作成功' })
                this.showInvalid = false
                this.initPageData()
              }
            }
          )
        }
      })
    }
  },
  computed: {
    nullifiable: function () {
      return this.agreementBean.status !== -1 && this.isAuth('Agreement_Invalid')
    }
  }
}
</script>
